<div class="layout">
  <a
    href="#"
    id="header-menu-button"
    (click)="changeSidebarStatus()"
    [ngClass]="{ 'header-menu-active': menuActive }"
  >
    <em class="pi pi-align-justify"></em>
  </a>

  <a
    href="#"
    id="topbar-profile-menu-button"
    (click)="userMenuActive = !userMenuActive"
  >
    <div id="topbar-name-container">
      <span id="topbar-name">{{ user?.fullName }}</span>
      <span id="topbar-last-access"
        >Último ingreso: {{ user?.lastAccess }}</span
      >
    </div>
    <img
      alt="ecuador-layout"
      class="topbar-profile"
      src="assets/images/user.jpg"
    />
    <em class="pi pi-angle-down" style="color: white"></em>
  </a>

  <ul
    class="fadeInDown"
    [ngClass]="{ 'usermenu-active': userMenuActive }"
    id="topbar-usermenu"
  >
    <li class="topbar-name-mobile">
      <span>{{ user?.fullName }}</span>
      <span>Último ingreso: {{ user?.lastAccess }}</span>
    </li>
    <li>
      <a href="#" (click)="userMenuActive = false">
        <em class="pi pi-user mr-1"></em>
        <span class="topbar-item-name">Perfil</span>
      </a>
    </li>
    <li>
      <a href="#" (click)="userMenuActive = false">
        <em class="pi pi-envelope mr-1"></em>
        <span class="topbar-item-name">Mensajes</span>
      </a>
    </li>
    <li>
      <a id="logout" href="#" (click)="logout()">
        <em class="pi pi-power-off mr-1"></em>
        <span class="topbar-item-name">Cerrar Sesion</span>
      </a>
    </li>
  </ul>
</div>
