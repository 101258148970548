/**
 * Aqui se pueden definir variables que se cargan solo cuando se ejecuta el proyecto en el ambiente de desarrollo
 */
export const environment = {
  /**
   * indica si el ambiente es productivo, cuando es true se habilita la configuracion de angular para aplicaciones en producción
   */
  production: false,

  apiUrl: 'https://corebienestar.dllosura.com/api/'
};
