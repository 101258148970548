import {
  ActivatedRouteSnapshot,
  Router,
  RouterStateSnapshot
} from '@angular/router';
import { IdentityService } from './identity.service';
import { inject } from '@angular/core';

export const AuthorizationGuard = (
  route: ActivatedRouteSnapshot,
  state: RouterStateSnapshot
) => {
  const seusService = inject(IdentityService);
  const router = inject(Router);

  const doActivate = (state: RouterStateSnapshot): Promise<boolean> => {
    return new Promise((resolver) => {
      if (!seusService.isLoggedIn()) {
        seusService.getLoginForm().subscribe({
          next: (form: any) => {
            if (form === 'LoggedInYet') {
              checkAuthorization(state.url, resolver);
            } else {
              document.write(form);
            }
          },
          error: (error: any) => console.error(error)
        });
      }

      checkAuthorization(state.url.substring(1), resolver);
    });
  };

  const checkAuthorization = (
    url: string,
    resolver: (param: boolean) => void
  ): void => {
    seusService.isAuthorized(url).subscribe({
      next: (res: any) => {
        if (!res) {
          router.navigate(['access-denied']);
        }
        resolver(res);
      },
      error: (error: any) => {
        router.navigate(['access-denied']);
        console.error(error);
        resolver(false);
      }
    });
  };

  return doActivate(state);
};
