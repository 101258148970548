import { environment } from './../../../environments/environment';

/**
 * Constantes de la aplicación
 */
export class Constants {
  /** contexto de la aplicación, debe ser el mismo definido en el web.xml del backend */
  //static contextoServicios = 'api/';
  static contextoServicios = environment.apiUrl;

  /** url para obtener información del usuario */
  static securityUserInfo = 'security/user';

  /** url para concer si un usuario tiene permiso hacia un recurso */
  static securityUserAccess = 'security/access';

  /** url para obtener comprobar que un usuario tenga activa una sesión y en caso contrario
   *  obtener el html del formulario de login
   */
  static securityCheckStatus = 'security/status';

  // gen_constants
  static httpStatusOk = 200;
}
