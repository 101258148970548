<div class="wrapper">
  <div class="abs-center wd-xl">
    <!-- START panel-->
    <div class="text-center mb-xl">
      <div class="text-lg mb-lg">403</div>
      <p class="lead m0">Acceso denegado :(</p>
      <p>
        Lo sentimos! No tienes permisos para ver el recurso al que intentas
        acceder.
      </p>
    </div>
    <ul class="list-inline text-center text-sm mb-xl">
      <li><a class="text-muted" [routerLink]="'/'">Volver al Home</a></li>
    </ul>
  </div>
</div>
