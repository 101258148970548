import { Routes } from '@angular/router';

import { LayoutComponent } from '../layout/layout.component';
import { Error403Component } from '../shared/global-error-components';

import { AuthorizationGuard } from '../core/security/authorization-guard.service';
// gen_imports

/**
 * objeto que almacena la ruta base sobre la cual se asigna el modulo manejador
 */
export const APP_ROUTES: Routes = [
  {
    path: '',
    component: LayoutComponent,
    children: [
      { path: '', redirectTo: 'sample', pathMatch: 'full' },
      {
        path: 'sample',
        loadChildren: () =>
          import('./sample/sample.module').then((m) => m.SampleModule),
        canActivate: [AuthorizationGuard]
      },
      {
        path: 'company',
        loadChildren: () =>
          import('./company/company.module').then((m) => m.CompanyModule),
        canActivate: [AuthorizationGuard]
      },
      {
        path: 'cache_manager',
        loadChildren: () =>
          import('./cache-manager/cache-manager.module').then(
            (m) => m.CacheManagerModule
          ),
        canActivate: [AuthorizationGuard]
      }
    ]
  },

  // errors
  { path: 'access-denied', component: Error403Component },

  // Not found
  { path: '**', redirectTo: 'sample' }
];
